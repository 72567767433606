import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRawMilksList() {
  // Use toast
  const toast = useToast()

  const refProductCategory = ref(null)

  // Table Handlers
  const tableColumnProductCategory = [
    { key: 'nama_produk', sortable: false },
    { key: 'satuan', sortable: false },
    { key: 'deskripsi', sortable: false },
    { key: 'aksi', sortable: false },
  ]
  const perPageRawMilkSummary = ref(10)
  const totalGoatsRawMilkSummary = ref(0)
  const currentPageRawMilkSummary = ref(1)
  const perPageOptionsRawMilkSumarry = [10, 25, 50, 100]
  const searchQueryRawMilkSummary = ref('')
  const sortByProductCategory = ref('id')
  const isSortDirDescProductCategory = ref(true)

  const dataMetaRawMilkSummary = computed(() => {
    const localItemsCount = refProductCategory.value ? refProductCategory.value.localItems.length : 0
    return {
      from: perPageRawMilkSummary.value * (currentPageRawMilkSummary.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageRawMilkSummary.value * (currentPageRawMilkSummary.value - 1) + localItemsCount,
      of: totalGoatsRawMilkSummary.value,
    }
  })

  const refetchProductCategory = () => {
    refProductCategory.value.refresh()
  }

  watch([currentPageRawMilkSummary, perPageRawMilkSummary, searchQueryRawMilkSummary,], () => {
    refetchProductCategory()
  })

  const mapServiceProductCategoryToModel = ({
    id, 
    category, 
    unit, 
    description,
  }) => ({
    id:id, 
    icon_produk: require("@/assets/images/icons/milk.svg"),
    nama_produk: category === null ? 'N/A' : category,
    satuan: unit === null ? 'N/A' : unit,
    deskripsi: !description ? 'N/A' : description,
  });

  const fetchProductCategories = (ctx, callback) => {
    store
      .dispatch('app-product-category/fetchProductCategories')
      .then(response => {
        const data = response.data.data
        const dataMapped = data.map(mapServiceProductCategoryToModel)
        const { total } = response.data.data
        callback(dataMapped)
        totalGoatsRawMilkSummary.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Master Product Category',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchProductCategories,
    tableColumnProductCategory,
    perPageRawMilkSummary,
    currentPageRawMilkSummary,
    totalGoatsRawMilkSummary,
    dataMetaRawMilkSummary,
    perPageOptionsRawMilkSumarry,
    searchQueryRawMilkSummary,
    sortByProductCategory,
    isSortDirDescProductCategory,
    refProductCategory,

    refetchProductCategory,

  }
}
